<template>
  <div id="app">
      <template v-if="$route?.meta?.loginNotRequired">
        <router-view />
      </template>

      <template v-else-if="!$route?.meta?.loginNotRequired && $store.state.user">
        <Navigation v-if="!$route.meta?.disableNavigation" />
        <router-view v-if="!$route?.meta?.loginNotRequired && $store.state.user " />
      </template>

      <template v-else>
          <h1 class="text-center mt-5">Lädt...</h1>
      </template>

      <ExpiredSessionModal />
  </div>
</template>
<script>
import Navigation from '@/components/Navigation.vue'
import ExpiredSessionModal from '@/components/ExpiredSessionModal.vue'

export default {
    components: {
        ExpiredSessionModal,
        Navigation
    }
}
</script>