import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'

import 'drag-drop-touch'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/berlin/berlin.scss'

import APIClient from '@/APIClient'

Vue.config.productionTip = false

Vue.use(BootstrapVue)

export let vueApp;

export const apiClient = new APIClient(window.APP_CONFIG?.base_url ?? 'https://creationforge-api.k8s.lumaserv.dev')

apiClient.axios.interceptors.response.use(r => r, async err => {
  if (err.response.status === 401) {
    // There is a 50:50 chance that the router isn't initialized here and currentRoute just shows fullPath '/', so we need to use window.location
    if (!window.location.pathname.startsWith('/login')) {
      if (router.currentRoute?.meta?.loginNotRequired)
        return
      if (router.currentRoute?.meta?.redirectToLogin) {
        store.state.user = null
        await router.push({ name: 'login', query: { redirect: window.location.pathname, ...router.currentRoute.query } })
        return;
      }
      vueApp.$bvModal.show('expired-session-modal')
      return
    }
  }
  throw err
})

if (localStorage.getItem('auth_session')) {
  apiClient.setToken(localStorage.getItem('auth_session'))
}

export async function validateUser() {
  store.state.user = {}
  try {
    store.state.user = await apiClient.getCurrentUser()
  } catch (e) {
    console.error('Failed to retrieve userdata', e)
  }
}

window.userValidation = validateUser();

vueApp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
