import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    mobile: window.innerWidth < 720,
    user: null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

window.onresize = () => {
  store.state.mobile = window.innerWidth < 720
}

export default store
