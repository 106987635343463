import axios from 'axios'
import { apiClient } from '@/main'

export default class APIClient {
    #temporaryDownloadSession = null

    constructor(baseURL) {
        this.baseURL = baseURL
        this.axios = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'json',
            responseEncoding: 'utf8'
        })
        this.headers = {};
        this.setToken(undefined);
    }

    setToken(token) {
        this.token = token || null;
        if(this.token) {
            this.headers['Authorization'] = `Bearer ${this.token}`;
        } else {
            delete this.headers['Authorization'];
        }
    }

    request(method, path, body = null, queryParams = null, options = {}) {
        return new Promise((resolve, reject) => {
            this.axios.request({
                method,
                params: queryParams,
                url: path,
                data: body,
                headers: this.headers,
                ...options,
            }).then(response => {
                if (options?.raw_response) {
                    resolve(response)
                    return;
                }
                if (response !== true && response !== undefined) {
                    resolve(response?.data) // TODO
                } else {
                    reject({ status: -1, error: 'Invalid response schema', response })
                }
            }, error => {
                if(error.response) {
                    reject({ ...error.response.data, status: error.response.status });
                } else {
                    reject({ status: -1, error: error.toString() })
                }
            })
        })
    }

    async getAuthConfig() {
        return (await this.request('GET', '/auth/config')).data
    }

    async login(providerId, credentials, setToken = true) {
        const { data } = await this.request('POST', '/auth/login', {
            provider_id: providerId,
            credentials
        })
        if (setToken) {
            this.setToken(data.access_token)
        }
        return data
    }

    async getCurrentUser() {
        return (await this.request('GET', '/auth/self')).data
    }

    async getContents() {
        return (await this.request('GET', '/contents')).data
    }

    async getContent(id) {
        return (await this.request('GET', `/contents/${id}`)).data
    }

    async getLearningPaths() {
        return (await this.request('GET', '/learning-paths')).data
    }

    async getRecentlyOpened(id) {
        return (await this.request('GET', `/recentlyopened/${id}`)).data
    }

    async getLearningPath(id) {
        return (await this.request('GET', `/learning-paths/${id}`)).data
    }

    async updateLearningPath(id, update) {
        return (await this.request('PUT', `/learning-paths/${id}`, update)).data
    }

    async deleteLearningPath(id) {
        return (await this.request('DELETE', `/learning-paths/${id}`)).data
    }


    async getNodes(query = {}) {
        return (await this.request('GET', `/nodes`, null, query)).data
    }

    async getNode(id) {
        return (await this.request('GET', `/nodes/${id}`)).data
    }

    async updateNode(id, update) {
        return (await this.request('PUT', `/nodes/${id}`, update)).data
    }

    async deleteNode(id) {
        return (await this.request('DELETE', `/nodes/${id}`)).data
    }

    async moveNode(id, to) {
        return (await this.request('POST', `/nodes/${id}/move`, {
            parent_id: to
        })).data
    }

    async createNode(node) {
        return (await this.request('POST', '/nodes', node)).data
    }

    async createContent(content) {
        return (await this.request('POST', '/contents', content)).data
    }

    async updateContent(id, update) {
        return (await this.request('PUT', `/contents/${id}`, update)).data
    }

    async deleteContent(id, query = {}) {
        return (await this.request('DELETE', `/contents/${id}`, null, query)).data
    }

    async getFile(id) {
        return (await this.request('GET', `/files/${id}`)).data
    }

    async getFileURL(id) {
        return `${this.baseURL}/files/${id}?auth_token=${await this.getTemporaryDownloadSession()}`
    }

    async getFileBlobURL(id) {
        const { data } = await apiClient.request('GET', `/files/${id}`, null, null, {
            responseType: 'blob',
            raw_response: true
        })

        return URL.createObjectURL(data)
    }

    async getExportLearnPathURL(id, type) {
        return `${this.baseURL}/learning-paths/${id}/export/${type}?auth_token=${await this.getTemporaryDownloadSession()}`
    }

    async getFileData(id) {
        return new Promise((res, err) => {
            apiClient.request('GET', `/files/${id}`, null, null, {
                responseType: 'blob',
                raw_response: true
            }).then(({data}) => {
                const reader = new FileReader();
                reader.addEventListener("load", function() {
                    res(reader.result)
                }, false);

                reader.readAsArrayBuffer(data);
            }).catch(err)
        })
    }

    async getTemporaryDownloadSession() {
        if (this.#temporaryDownloadSession === null) {
            this.#temporaryDownloadSession = (await this.request('POST', '/auth/fork')).data.access_token

            setTimeout(() => {
                this.#temporaryDownloadSession = null
            }, 1000 * 45)
        }

        return this.#temporaryDownloadSession
    }

    async uploadFile(formData) {
        if (formData instanceof File) {
            const file = formData
            formData = new FormData()
            formData.append(`${file.name}`, file)
        }

        return (await this.request('POST', '/files', formData, null, {
            headers: {
                ...this.headers,
                'Content-Type': 'multipart/form-data'
            }
        })).data
    }

    async createLearningPath(learningPath) {
        return (await this.request('POST', '/learning-paths', learningPath)).data
    }
}