import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      redirectToLogin: true
    },
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/web-editor',
    name: 'home',
    component: () => import('../views/WebContentEditorPreview.vue')
  },
  {
    path: '/learnpaths',
    name: 'learnpaths',
    meta: {
      redirectToLogin: true
    },
    component: () => import('../views/learnpaths/LearnPathsOverview.vue')
  },
  {
    path: '/library',
    name: 'library',
    meta: {
      redirectToLogin: true
    },
    component: () => import('../views/LibraryView.vue')
  },
  {
    path: '/quiz-editor/:contentId',
    name: 'quiz-editor',
    component: () => import('../views/QuizEditor.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      disableNavigation: true,
      loginNotRequired: true
    },
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/login/return',
    name: 'login-return',
    meta: {
      disableNavigation: true,
      loginNotRequired: true
    },
    component: () => import('../views/auth/LoginReturn.vue')
  },
  {
    path: '/learnpaths/:id',
    name: 'learnpath',
    component: () => import('../components/learnpaths/LearnPathNavigation.vue'),
    children: [
      {
        path: '',
        name: 'learnpath-main',
        component: () => import('../views/learnpaths/LearnPathOverview.vue')
      },
      {
        path: ':folder*',
        name: 'learnpath-element',
        component: () => import('../views/learnpaths/LearnPathElement.vue')
      }
    ]
  },
  {
    path: '/*',
    name: 'not-found',
    meta: {
    },
    component: () => import('../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
