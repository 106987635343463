<template>
    <nav class="navigation">
        <router-link class="navigation-logo" :to="{name: 'home'}">
            <img v-if="$store.state.mobile" src="/assets/berlin/small-logo.svg" alt="Berlin Logo">
            <img v-else src="/assets/berlin/logo_berlin.svg" alt="Berlin Logo">
        </router-link>

        <div class="navigation-links">
            <router-link :to="{name: 'learnpaths'}">Lernpfade</router-link>
            <router-link :to="{name: 'library'}">Inhalts-Bibliothek</router-link>
        </div>

        <div aria-label="Nutzermenü öffnen/schließen" class="navigation-user-section" v-if="$store.state?.user" id="user-menu" @click.stop="userMenuShown = !userMenuShown">
            <span>{{ $store.state.user.name }}</span>
            <div class="profile-picture">
                <span>{{ $store.state?.user.name?.at(0)?.toUpperCase() ?? '?' }}</span>
            </div>
        </div>

        <div aria-label="Nutzermenü" @click.stop class="user-dropdown" v-if="userMenuShown">
            <p class="text-muted">{{ $store.state.user.name }}</p>
            <b-button variant="primary-link" class="full-width" @click="logout">Ausloggen</b-button>
        </div>
    </nav>
</template>
<script>
import store from '@/store'

export default {
    data: () => ({
        userMenuShown: false
    }),
    computed: {
        store() {
            return store
        }
    },
    watch: {
        userMenuShown(to) {
            if (to) {
                window.addEventListener('click', this.userMenuWindowEvent)
            } else {
                window.removeEventListener('click', this.userMenuWindowEvent)
            }
        }
    },
    methods: {
        logout() {
            localStorage.removeItem('auth_session')
            window.location.href = '/login'
        },
        userMenuWindowEvent() {
            this.userMenuShown = false
        }
    }

}
</script>
<style scoped lang="scss">

</style>