<template>
    <b-modal
        id="expired-session-modal"
        title="Sitzung abgelaufen"
        @ok="confirm"
        ok-title="Ja"
        cancel-title="Nein"
    >
        Ihre Sitzung is abgelaufen, möchten Sie sich erneut anmelden? Nicht gespeicherte Änderungen gehen dabei verloren.
    </b-modal>
</template>
<script>
export default {
    name: 'ExpiredSessionModal',
    methods: {
        async confirm() {
            this.$store.state.user = null
            await this.$router.push({ name: 'login', query: { redirect: window.location.pathname, ...this.$route.query } })
        }
    }
}
</script>